@import "./variables";
@import "./flex_mixins";
@import "./grid_mixins";
@import "./mixins";

@import url('https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Dosis:700');

// @font-face {
//     font-family: 'SpaceGrotesk';
//     src: url('../../fonts/SpaceGrotesk-Regular.woff') format('woff'),
// 		  url('../../fonts/SpaceGrotesk-Regular.woff2') format('woff2');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'SpaceGrotesk';
//     src: url('../../fonts/SpaceGrotesk-Bold.woff') format('woff'),
// 		  url('../../fonts/SpaceGrotesk-Bold.woff2') format('woff2');
// 	font-weight: 800;
// }


// @font-face {
//     font-family: 'SpaceGrotesk';
// 	src: url('../../fonts/SpaceGrotesk-SemiBold.woff') format('woff'),
// 		  url('../../fonts/SpaceGrotesk-SemiBold.woff2') format('woff2');
// 	font-weight: 600;
// }


// @font-face {
//     font-family: 'SpaceGrotesk';
// 	src: url('../../fonts/SpaceGrotesk-Medium.woff') format('woff'),
// 		  url('../../fonts/SpaceGrotesk-Medium.woff2') format('woff2');
// 	font-weight: 400;
// }

html {
    font-size: 1em;
    line-height: 1.25;
}

body {
	margin: 0px;
	padding: 0px;
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: "100%";
	color: #333333;
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }
  

/**
Links
*/
a {
	text-decoration: none;
	color: $tintColor;
}
a.action {
	font-size: 21px;
	font-weight: 600;
}

h1, h2, h3, h4 {
	margin: 0px;
	color: $black;
	-webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
}


/* 
 Titles
 ------------------

*/
h1 {
	font-family: "Dosis";
	font-size: 32px;
	line-height: 1.1;
	margin: 0px !important;
	color: $futureAmpTint;
	/* iPad & Desktop */
	@media only screen and (min-width: $breakpoint-small) {   
		font-weight: 700;
		font-size: 46px;
		letter-spacing: -1.0px;  
	}
}

h1.accent {
	max-width: 100% !important;
	padding-top: 5px;
	color: $tintColor;
}

h2 {
	font-family: "Dosis";
	font-size: 32px;
	line-height: 1.1;
	margin: 0px !important;
	color: $futureAmpTint;
	/* iPad & Desktop */
	@media only screen and (min-width: $breakpoint-small) {   
		font-weight: 700;
		font-size: 38px;
		letter-spacing: -1.0px;  
	}
}

h3 {
	font-family: -apple-system, "Roboto";
	font-size: 20px;
	line-height: 1.5;
	margin: 0px !important;
	/* iPad & Desktop */
	@media only screen and (min-width: $breakpoint-small) {   
		font-weight: 600;
		font-size: 20px;
	}
}

h4 {
	font-family: -apple-system, "Roboto";
	font-size: 20px;
	line-height: 1.1;
	margin: 0px !important;
	/* iPad & Desktop */
	@media only screen and (min-width: $breakpoint-small) {   
		font-weight: 700;
		font-size: 20px;
	}
}

header .inner p.super, p.super {
	font-size: 16px;
	color: #5271FF;
	padding-bottom: 0px;
	font-weight: 600;

	@media only screen and (max-width: $breakpoint-small) {
		font-size: 14px;
	}

}

h1.highlight {
	font-size: 45px;
	padding-bottom: 7px;
	padding-top: 5px;	

	@media only screen and (min-width: $breakpoint-small) {   
		font-size: 60px;
		padding-bottom: 15px;
		padding-top: 2px;	
	}
}
h1.highlight, h2.highlight { 
	display: inline-block;
	height: 45px;
	width: auto;
	
	span {
		display: inline-block;
		position: relative;
		z-index: 2;	
	}
	.image {
		position: relative;
		top: -50px;
		height: 50px;
		width: 110%;
		left: -5px;

		@media only screen and (min-width: $breakpoint-small) {   
			top: -55px;
			height: 50px;
			width: 115%;
			left: -15px;
		}

		z-index: 1;
		background: url('/header-highlight.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	
}

.subhead {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	
	/* or 45px */
	letter-spacing: 0.12em;
	margin-bottom: 5px;
	display: block;
	color: $tintColor !important;
}

.subhead-small {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
	font-style: normal;
	font-weight: 800;
	font-size: 12px;
	
	/* or 45px */
	letter-spacing: 0.05em;
	margin-bottom: 8px;
	display: inline-block;
	color: $tintColor !important;
	text-transform: uppercase;
}

span.tint {
	color: $tintColor;
}

/*
  Content Text
  -------------
*/
p.header {
	font-size: 21px;
	line-height: 1.6;
	color: #000000;
}

p {
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
	margin: 0px;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 17px;
	@media only screen and (min-width: $breakpoint-small) {   
		font-size: 18px;
	}
	line-height: 1.6;

	color: $textColor;
}

/*
  Navigation Classes
  ------------------------------------
  nav.pageNavigation
*/
nav {

}

/*

  Page Container Classes
  ------------------------------------
  main.page
    section.section
        section.section-content
          div.copy-container

*/

main.page {
	background-color: white;
	margin: 0px;
	padding: 0px;
	border: 0px;
	margin-top: 70px;
	padding-bottom: 0px;
}

section.header {
	padding-top: 80px;
}

.section-header {
	padding-bottom: 15px;
	h4 {
		color: $black;
	}
	p {
		margin-top: 5px;
		margin-bottom: 0px;
		
	}
	@media only screen and (max-width: $breakpoint-small) {
		a { 
			display: block;
			margin-top: 4px;
			margin-bottom: 10px;
		}
	}
	@media only screen and (min-width: $breakpoint-small) {
		a {
			position: relative;
			float: right;
			top: -22px;
			left: 0px;
			font-weight: bold;
		}
	}
}


section.intro {
	padding-top: 20px;

	@media only screen and (min-width: $breakpoint-small) {
		padding-top: 60px;
	}

	p {
		margin-top: 5px;
	}
}


section.split {
	background: linear-gradient(180deg, #FAFAFA 0%, rgba(255, 255, 255, 0) 40.92%);
	padding-top: 75px;
	@media only screen and (min-width: $breakpoint-small) {
		padding-top: 125px;
		padding-bottom: 0px;	
	}
}

// Used for contained content
header .content, section .content {
	margin-left: auto;
	margin-right: auto;

	padding-left: 25px;
	padding-right: 25px;  

	width: auto;
	
	@media only screen and (min-width: $breakpoint-small) {
		padding-left: 25px;
		padding-right: 25px;
		wwidth: 665px;
		width: calc(100vw - 25px - 25px);
	} 

	@media only screen and (min-width: $breakpoint-medium) {
		padding: 0px;
		wwidth: 880px;
		padding-left: 50px;
		padding-right: 50px;
		width: calc(100vw - 50px - 50px);
	}   

	@media only screen and (min-width: $breakpoint-large) {
		padding: 0px;
		width: 980px;
	}   
}
  
// Used for 100% content
section .banner {
	margin-left: auto;
	margin-right: auto;
	width: 100%;

	@media only screen and (min-width: $breakpoint-small) {
		width: 100%;
	}
	@media only screen and (min-width: $breakpoint-large) {
		width: $breakpoint-large;
	}
}

.copy-container {
	margin-top: 25px;
	margin-bottom: 25px;

	@media only screen and (min-width: $breakpoint-small) {
		margin-top: 50px;
		margin-bottom: 50px;
	}
}

.full-centered {
    height: calc(100vh - 70px);
    margin: 0px;
    padding: 0px;
    margin-top: -70px;
    @include flex;
    @include flex-center;
    @include flex-middle;
}


/**
	Headers
*/
div.intro p, p.intro {
	font-size: 20px;
	padding-bottom: 15px;
	ffont-weight: 400;
	@media only screen and (max-width: $breakpoint-small) {
		font-size: 19px;
	}
}
div.intro-border p, p.intro-border {
	border-top: 1px solid #e3e3e3;
	padding-top: 40px;
}
header.page-header {
	background-color: #F5F8FF;
	padding-top: 20px;
	padding-bottom: 45px;
	border-bottom: 1px solid #F0F0F0;
	margin-bottom: 65px;

	@media only screen and (max-width: $breakpoint-small) {
		padding-top: 15px;
		padding-bottom: 30px;	
		margin-bottom: 45px;
	}

	h1 {
		padding-top: 0px;
		padding-bottom: 5px;
		color: #E7632B;
	}

	p {
		margin-top: 4px;
		margin-bottom: 5px;
		font-size: 20px;
		color: #464646;
		
		@media only screen and (max-width: $breakpoint-small) {
			font-size: 16px;
		}
	}

	p.super {
		margin-top: 12px;
	}

	.stem-logo {
		background: url('/stem-icon.png');
		background-size: 64px 64px;
		width: 64px;
		height: 64px;
		margin-left: -2px;

		@media only screen and (max-width: $breakpoint-small) {
			background-size: 48px 48px;
			width: 48px;
			height: 48px;	
		}
		
	}
}


/*
 	Row Grid

*/
// Used for grid content
.row {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 0px;
}

.column-full {
	@include column-full
}

.column-half-left {
	@include column-half-left
}
.column-half-right {
	@include column-half-right
}


/**

Grid

*/
.grid-4-column, .grid-3-column, .grid-2-column {
	grid-template-columns: 1.0fr;
}

@media only screen and (min-width: $breakpoint-small) {
	.grid-4-column {
		grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
	}

	.grid-3-column {
		grid-template-columns: 0.3333333fr 0.3333333fr 0.3333333fr;
	}

	.grid-2-column {
		grid-template-columns: 0.5fr 0.5fr;
	}
}

/*
	Forms
	---------------
*/
form {
	margin: 0px;
	padding: 0px;
}

.form-row {
	margin-bottom: 45px;
	@media only screen and (max-width: $breakpoint-small) {   
		margin-bottom: 0px;
	}
	label {
		font-size: 19px;
		font-weight: 700;
		display: block;
		padding-bottom: 10px;

		@media only screen and (max-width: $breakpoint-small) {   
			
		}
	}

	input.textfield {
		margin: 0px;
		display: block;
		width: 100%;
		height: 55px;
		background-color: #F8F8F8;
		border: 0px;
		border-radius: 6px;
		font-weight: 600;
		font-size: 16px;
		-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
		box-sizing: border-box;         /* Opera/IE 8+ */
		padding-left: 15px;
	}


	input.half {
		width: calc(50% - 11px);
		margin-right: 5px;
	}
	.form-column-half {
		display: inline-block;
		width: calc(50% - 22px);
		margin-right: 20px;

		@media only screen and (max-width: $breakpoint-small) {   
			width: 100%;
			
		}
	}
	.form-column-half, .form-column-full {
		@media only screen and (max-width: $breakpoint-small) {   
			margin-bottom: 40px;
		}
	}
	.form-column-grouped {
		@media only screen and (max-width: $breakpoint-small) {   
			margin-bottom: 10px;
		}
	}
}
form {
	textarea {
		font-family: "Roboto", -apple-system;
		margin: 0px;
		display: block;
		width: 100%;
		min-height: 150px;
		background-color: #F8F8F8;
		border: 0px;
		padding: 10px;
		border-radius: 6px;
		font-weight: 600;
		font-size: 16px;
		-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
		-moz-box-sizing: border-box;    /* Firefox, other Gecko */
		box-sizing: border-box;         /* Opera/IE 8+ */
		padding-left: 15px;
	}	
}

.form-actions {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 0px;
}

.form-action-right {
	
	grid-column: 7 / span 6;

	@media only screen and (min-width: $breakpoint-small) {
		grid-column: 10 / span 3;
	}
}

.form-action-center {
	
	grid-column: 1 / span 16;

	@media only screen and (min-width: $breakpoint-small) {
		grid-column: 5 / span 4;
	}
}



.form-action-info {
	grid-column: 1 / span 4;

	@media only screen and (min-width: $breakpoint-small) {
		grid-column: 1 / span 9;
	}
}

.form-row-condensed {
	margin-bottom: 25px;
}

.form-hint {
	text-align: center;
	font-family: "SpaceGrotesk";
	font-size: 13pt;
	a {
		text-decoration: none;
		color: $tintColor;
	}
}

.form-sticky-footer {
	box-shadow: 0px -10px 20px rgba(0,0,0,0.05);
	background-color: white;

	margin-top: 25px;
	padding-top: 25px;
	padding-bottom: 25px;
	@media only screen and (min-width: $breakpoint-small) {
		margin-top: 50px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	position: fixed;
	left: 0;
	bottom: 0;	
	width: 100%;

}

.form-fixed-footer {
	
	background-color: white;

	margin-top: 25px;
	padding-top: 25px;
	padding-bottom: 25px;
	@media only screen and (min-width: $breakpoint-small) {
		margin-top: 50px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	width: 100%;

	padding-top: 0px;
	.content {
		border-top: 1px solid #e3e3e3;
		padding-top: 50px;
	}
}

button.submit {
	transition: 0.1s;
}
button.primary-medium {
	height: 50px;
	border-radius: 6px;
	width: 100%;
	background-color: $tintColor;
	color: white;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
}

button.primary {
	height: 60px;
	border-radius: 6px;
	width: 100%;
	border: 0px;
	color: $tintColor;
	background-color: $tintColor;
	color: white;
	font-size: 19px;
	font-weight: 600;
	cursor: pointer;
}
button.auto-width {
	width: auto;
	padding-right: 20px;
	padding-left: 20px;
}

button.primary-disabled {
	height: 60px;
	border-radius: 6px;
	width: 100%;
	border: 0px;
	background-color: #F8F8F8;
	color: #8b8b8b;
	font-size: 19px;
	font-weight: 600;
}

button.primary:hover {
	background-color: $tintColorHover;
	color: white;
}

/**
	
	Messages

*/
.message {
	padding: 20px;
	display: block;
	color: white;
	font-size: 16px;
	text-align: center;
	margin-bottom: 35px;
	border-radius: 12px;
}
.message-error {
	background-color: #FF5B67;
}


/**

Word Pill

*/
.word-pill {
	background-color: #F8F8F8;
	padding: 20px;
	border-radius: 6pt;
	color: $tintColor;
	font-size: 19px;
	font-weight: 600;
	width: "100%";
	cursor: pointer;
	@include unselectable;	
	transition: 0.1s;
}

.word-pill:hover {
	transition: 0.2s;
	background-color: #E9E9E9;
}

.word-pill.selected {
	background-color: $tintColor;
	color: white;
}

.word-pill.selected:hover {
	background-color: #0038FF;
	color: white;
}

.word-pill.double-height {
	min-height: 45px;
	@include flex;
	@include flex-middle;
}



/**

Skill Card

**/
.attribute-card {
	.icon {
		background-size: cover;
		background-position: center center;
		background-color: #F8F8F8;
		width: 225px;
		height: 225px;
		border-radius: 50%;
		clip: true;
		margin: 0 auto 0 auto;
	}

	color: $tintColor;
	font-size: 21px;
	font-weight: 600;
	width: "100%";
	cursor: pointer;
	@include unselectable;	
	h5 {
		color: $tintColor;
		color: $black;
		font-size: 19px;
		text-align: center;
		margin: 0px;
		margin-top: 25px;
	}
}

.attribute-card.condensed {
	min-height: 50px;
}

.attribute-card.selected {

	h4 {
		color: $tintColor;
	}

	border: 5px solid $tintColor;
}

.attribute-card-alt {
	@include flex;
	@include flex-center;
	@include flex-middle;
	
	background-size: 75%;
	background-position: center center;
	background-color: #F8F8F8;
	width: 225px;
	height: 225px;
	border-radius: 50%;
	clip: true;
	margin: 0 auto 0 auto;
	
	color: $tintColor;
	font-size: 21px;
	font-weight: 600;
	width: "100%";
	cursor: pointer;
	@include unselectable;	
	h5 {
		color: $tintColor;
		color: $black;
		font-size: 26px;
		text-align: center;
		margin: 0px;
	}
}

.attribute-card-alt.condensed {
	min-height: 50px;
}

.attribute-card-alt.selected {

	h4 {
		color: $tintColor;
	}

	border: 5px solid $tintColor;
}




/**

Big textfield

*/
.big-textfield {
	cursor: pointer;
	padding-bottom: 20px;
	padding-top: 20px;
	
	span {
		font-weight: 600;
		font-size: 30px;
		@media only screen and (min-width: $breakpoint-small) {
			font-size: 38px;
		}
		color: rgba(0,0,0,0.35);
	}
	input {
		font-weight: 600;
		font-size: 30px;
		border: 0px;
		color: $tintColor;
		border-bottom: 2px solid $tintColor;
		
		width: 100%;
		
		@media only screen and (min-width: $breakpoint-small) {
			font-size: 38px;
			margin-left: 15px;
			width: 70%;	
			padding-bottom: 20px;
		}
	}
	input:focus {
		outline: none;
	}
}

/**

Scale Control

*/
.scale-control {
	.items {
		display: grid;
		grid-template-columns: repeat(9, 1fr);
		grid-column-gap: 0px;	

		.item {
			width: 20px;
			height: 20px;

			@media only screen and (min-width: $breakpoint-small) {
				width: 55px;
				height: 55px;
			}
			
			border-radius: 50%;
			background-color: #F1F1F1;
			cursor: pointer;
			transition: 0.15s;
		}
		.item:hover {
			background-color: #9B9B9B;
		}
		.item.selected {
			background-color: $tintColor;
			box-shadow: 0px 8px 20px rgba(56,99,252,0.35);
			transform: scale(1.1);
		}
	}

	.labels {
		color:rgba(0,0,0,0.35);
		margin-top: 20px;
		display: grid;
		text-align: center;
		@media only screen and (min-width: $breakpoint-small) {
			padding-right: 50px;
		}
		font-weight: 600;
		grid-template-columns: repeat(3, 1fr);			
	}
	.labels span:first-child {
		text-align: left;
	}
	.labels span:last-child {
		text-align: right;
	}

}


/**

Mentor Card

*/
.mentor-grid {
	.mentor-card {
		margin-bottom: 50px;
	}
}
.mentor-card {
	
	font-size: 12pt;
	
	.image {
		background-color: silver;
		width: 100%;
		background-size: cover;
		background-position: 50% 50%;
		height: 200px;
		@media only screen and (min-width: $breakpoint-medium) {
			height: 300px;
		}
		border-radius: 4px;	
	}
	.name {
		padding-top: 15px;
		font-weight: 800;
		color: $black;
	}
	.role {
		padding-top: 5px;
		font-weight: 800;
		color: $greyText;
	}
}

// .mentor-card:hover {
// 	.image {
// 		transform: scale(1.02);
// 		box-shadow: 0px 10px 20px rgba(0,0,0,0.2);
// 	}
// }



/**

Action Button

*/
.action-button {
	cursor: pointer;
	padding: 15px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 8px;
	box-shadow: 0px 5px 15px rgba(0,0,0,0.15);
	transition: 0.2s;	
	font-size: 18px;
	color: $tintColor !important;
}

.action-button:hover {
	box-shadow: 0px 10px 30px rgba(0,0,0,0.25);
}

