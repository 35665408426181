@import "../App/variables";
@import "../App/flex_mixins";
@import "../App/grid_mixins";

section.hero {
	background-color: $futureAmpTint;
	h1 {
		color: white !important; 
	}
	p {
		color: rgba(255,255,255,0.5) !important; 
	}
	background-image: url('/header-bg.png');
	background-size: 100% 100%;
	@include flex;
	@include flex-middle;
	@include flex-center;
	margin-bottom: 50px;
}

section.hero .copy-container {
	@media only screen and (max-width: $breakpoint-small) {
		padding-top: 15px;
		@include column-full;
	}

	@media only screen and (min-width: $breakpoint-small) {
		@include column-half-left;
		padding-right: 125px;
	}
	
	height: auto;
	@include flex;
	@include flex-middle;
}

section.hero .image {
	@media only screen and (max-width: $breakpoint-small) {
		@include column-full;
		height: 500px;
		width: 450px;
		margin-top: -35px;
		margin-left: -50px;
		margin-bottom: 50px;
	}

	@media only screen and (min-width: $breakpoint-small) {
		@include column-half-right;
		height: 600px;
		width: 500px;	
	}

	background-image: url('/banner-image.png');
	background-size: cover;
	background-position: center;
	display: block;
}
