@import "../App/variables";
@import "../App/flex_mixins";
@import "../App/grid_mixins";
@import "../App/mixins";

.pack {
    margin-top: 0px;
    padding: 50px;
    @media only screen and (max-width: $breakpoint-small) {   
        padding-left: 0px;
        padding-right: 0px;
    }
    padding-bottom: 50px;
    .content {
        ppadding-top: 50px;
        border-bottom: 1px solid #e3e3e3;
    }
    bborder-bottom: 1px solid #e3e3e3;
    bbackground-color: $tintColor;

    // Colors
    bbackground-color: $tintColor;



    h2, p {
        ccolor: white;
    }
    p {
        margin-top: 10px !important;
        margin-bottom: 20px !important;
        opacity: 1.0;
    }

    a.large-round-button.primary {
        font-weight: bold;
        bbackground-color: white;
        ccolor: $futureAmpTint;
    }

}

.course-complete { 
    h3 {
        font-size: 32px;
        color: $tintColor;
        font-family: "Dosis";
    }

    .copy-container {
        max-width: 650px;
        @media only screen and (max-width: $breakpoint-small) {   
            max-width: auto;
        }
        margin-left: auto;
        margin-right: auto;
    }
    p {
        margin-top: 20px;
        margin-bottom: 20px;
    } 

    .certificate p {
        margin-top: 10px;
    }

    header {

        p.super {
            padding-bottom: 0px;
            margin-bottom: 0px;
        }    
        .congrats {
            ccolor: $tintColor;
            padding-bottom: 10px;
            ffont-size: 42px;
        }
        
        ppadding-top: 0px;
        background-color: #F5F8FF;
        min-height: 420px;
        padding-top: 50px;
        padding-bottom: 0px;
        margin: 0px !important;
        .copy-container {
            margin-bottom: 0px;
        }
    }

    .certificate {
        bbackground-color: #F5F8FF;
        overflow: hidden;
        margin-top: 0px;
        padding-top: 50px;
        @media only screen and (max-width: $breakpoint-small) {  
            margin-top: 0px;
        }
    }
    .certificate-container {
        transform: scale(0.8);
        width: 980px; 
        background-color: clear;
        background-color: #F5F8FF;
        height: 1200px;
        border-radius: 50px;
        margin: 0 auto 0 auto;
        box-shadow: 0px 40px 80px rgba(0,0,0,0.25);
        margin-top: -40px;
        @media only screen and (max-width: $breakpoint-small) {   
            width: calc(100vw); 
            overflow: hidden;
            background-color: clear;
            height: 550px; 
            margin-left: 0px;
            transform: scale(1.0);
            box-shadow: 0px 0px 0px;
            svg {
                margin: 0 auto 0 auto;
                width: 100%;
                margin-left: 17px;
                height: 550px;
            }
            
        }
    }
}

a.large-round-button {
    padding: 20px;
    padding-left: 55px;
    padding-right: 55px;
    border-radius: 200px;
    margin-bottom: 50px;
    min-width: 350px;
    display: inline-block;
    font-weight: bold;
    background-color: #EFEFEF;
    color: $tintColor;
    @media only screen and (max-width: $breakpoint-small) {   
        padding: 20px;
        white-space: nowrap;
        padding-left: 25px;
        padding-right: 25px;    
    }
}

a.large-round-button.primary {
    font-weight: bold;
    background-color: $tintColor;
    color: white;
}