@mixin column-full {
	grid-column: 1 / span 12;
}

@mixin column-half-left {
	grid-column: 1 / span 6;
}

@mixin column-half-right {
	grid-column: 7 / span 6;
}


@mixin column-half-center {
	grid-column: 4 / span 6;
}