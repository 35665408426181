// $breakpoint-small: 526px !default;
// $breakpoint-medium: 660px !default;
// $breakpoint-large: 820px;

$breakpoint-small: 660px !default;
$breakpoint-medium: 820px !default;
$breakpoint-large: 1030px;


// Colors
$futureAmpTint: #E7632B;
$futureAmpTintAlt: #E7632B;

$black: #333333;
$greyText: #A1A1A1;
$greyBackground: #F8F8F8;
$placeholder: #ababab;
$textColor: #464646;

$tintColor:#5271FF;
$tintColorHover: #0029BB;
$accentColor: #F8D042;

$headerFontFamily: 'SpaceGrotesk';

$placeholderColor: rgb(82, 82, 82);

$borderColor: #e3e3e3;