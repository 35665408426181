@import "../App/variables";
@import "../App/flex_mixins";
@import "../App/grid_mixins";

nav.navigation-container {
	width: 100%;
	padding: 0px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	height: 70px;
	display: flex;
	align-items: center;
	top: 0px;
	z-index: 1002;
	white-space: nowrap;	
	
	
	ul {
		list-style: none;
		align-items: center;
		margin: 0px;
		padding: 0px;
		white-space: nowrap;	
		@media only screen and (max-width: $breakpoint-small) {
			margin-left: 25px;
			margin-right: 25px;
		}
	
	}

	li {
		vertical-align: center;
		list-style-type: none;
		text-decoration: none;
		color: #31affd;		
		font-size: 16px;
		font-weight: 700;
		line-height: 35px !important;
		white-space: nowrap;	

		@media only screen and (max-width: $breakpoint-small) {
			font-size: 14px;
		}

	}

	li a {
		text-decoration: none;
		color: $tintColor;
	}
	li a:hover {
		color: $tintColorHover;
	}

	li.primary {
		border-radius: 6px;
		background-color: $tintColor;
		margin: 0px;
		padding: 0px;
		display: block;
		transition: 0.15s;
		a {
			color: white;
			padding: 30px;
		}
	}
	li.primary:hover {
		box-shadow: 0px 5px 15px rgba(56,99,252,0.35);
		background-color: $tintColorHover;
	}

	.navigation {
		margin-top: auto;
	}

	.navigation-content {
		padding: 0px;

		width: 100%;
		@media only screen and (min-width: $breakpoint-small) {
			widthh: 665px;
			padding-left: 25px;
			padding-right: 25px;
			width: 100%;
		}

		@media only screen and (min-width: $breakpoint-medium) {
			width: 880px;
			padding-left: 50px;
			padding-right: 50px;
			width: 100%;
		}

		@media only screen and (min-width: $breakpoint-large) {
			width: 980px;
		}
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.navigation-left {
		height: auto;
		display: inline-block;
		align-self: flex-start;

		li {
			margin-right: 20px;
			float: left;
			height: auto;
			@media only screen and (max-width: $breakpoint-small) {
				margin-right: 0px;
			}	
		}
	}

	.navigation-right {
		height: auto;
		display: inline-block;
		align-self: flex-end;

		li {
			margin-left: 20px;
			float: right;
			height: auto;
			@media only screen and (max-width: $breakpoint-small) {
				margin-left: 10px;
			}	

		}
	}
}

nav.white {
	background-color: rgba(255, 255, 255, 0.95);
	border-bottom: 1px solid rgba(199, 199, 199, 0.25);
}

nav.fixed {
	position: fixed;
	top: 0px;
}

nav li.logo {
	background: url(/logo.png);
	background-size: 155px 57px;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	height: 57px;
	width: 155px;
}

nav li.logo a {
	display: block;
	height: 57px;
	width: 155px;
}