@mixin flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flex-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@mixin flex-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

@mixin flex-middle {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@mixin flex-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@mixin flex-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

@mixin flex-auto {
  -ms-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  /* 1 */
  -ms-flex-negative: 1;
}

@mixin flex-no-wrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
