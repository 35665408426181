@import "../App/variables";
@import "../App/flex_mixins";
@import "../App/grid_mixins";
@import "../App/mixins";


@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
blockquote{
-moz-box-sizing: border-box; 
-webkit-box-sizing: border-box; 
	box-sizing: border-box; 
  font-size: 1.4em;
  width:100%;
  margin: 60px auto;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:1.2em 75px 1.2em 75px;
  bborder-left:8px solid $tintColor;
  bborder-left:8px solid $futureAmpTint;
  //border:0px solid $futureAmpTint;
  border-radius: 20px;
  line-height:1.6;
  position: relative;
  background: #eeeeee;
  background-color: #F5F8FF;
  color: white !important;
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color: $futureAmpTint;
  font-size:4em;
  position: absolute;
  left: 15px;
  top:-10px;
}

blockquote::after{
	font-family:Arial;
	content: "\201D";
	color: $futureAmpTint;
	font-size:4em;
	position: absolute;
	right: 40px;
	bottom: -60px;
}

// blockquote::after{
//   content: '';
// }

blockquote span , blockquote p {
  display:block;
  color:#333333;
  ccolor: $tintColor;
  font-style: normal;
  font-style: italic;
  ffont-weight: 500;
  mmargin-top:1em;
}

.step {
	padding-bottom: 50px;
	section.component {
		margin-bottom: 70px;
	}
	section h3 {
		bborder-top: 1px solid #e3e3e3;
		ppadding-top: 22px;
		padding-bottom: 22px;
	}
	section h3.no-separator {
		border: 0px;
	}
}

.progressBar {
	width: "100%";
	background-color: #e3e3e3;
	border-radius: 20px;
	height: 10px;
	margin-top: 13px;
	.inner {

		border-radius: 15px;
		height: 10px;
		background-color: $tintColor;
	}
}
.landing {
	text-align: center;


	header {
		p {
			max-width: 700px;
			margin: 25px;
			margin: 0 auto 0 auto;
			padding: 15px;
		}

		background-color: white;
		padding-top: 20px;
		border-bottom: 0px solid #F0F0F0;
		h1 {
			margin: 0 auto 0 auto !important;
			max-width: 550px;
		}
	}
	
	.cover-image {
		margin: 0 auto 0 auto;
		width: 640px;
		height: 426px;
		height: 480px;
		width: 720px;
		wwidth: 1280px;
		hheight: 853px;
		mmargin-left: -120px;
		background-image: url('/course-cover2.jpg');
		background-size: cover;
		background-position: center;	
	}

	.credits {
		.copy-container {
			border-top: 1px solid #e3e3e3;
			padding-top: 50px;
			margin-top: 100px;
		}
		
		p {
			color: #333333;
			font-size: 15px;
			font-weight: normal;
			margin: 20px;
		}
		a {
			font-weight: bold;
			color: #000000;
		}
	}
}

.video {
	background-color: #969696;

	width: 100%;
	height: 175px;
	background-size: cover;
	background-position: center center;

	@media only screen and (min-width: $breakpoint-small) {
		width: 100%;
		height: 410px;
		mmargin: -200px auto 0 auto;  
	}

	@media only screen and (min-width: $breakpoint-large) {
		width: 100%;
		height: 551px;
		mmargin: -200px auto 0 auto;    
	} 

	@media only screen and (min-width: 1100px) {
		width: 100%;
		height: 551px;
		mmargin: -200px auto 0 auto;    
	} 
	
	cursor: pointer;

	@include flex;
	@include flex_center;
	@include flex_middle;

	.play-button {
		width: 80px;
		height: 80px;
		display: block;
		background-color: white;
		border-radius: 40px;
		background-image: url('/play-arrow.svg');
		background-repeat: no-repeat;
		background-position: 53% center;
		background-size: 24px 24px;
		transition: 0.3s;
	}

}

.video:hover {
	.play-button {
		transform: scale(1.1);
	}        
}

.component-item-rate-large {
	.radio-items {
		width: 100%;		
		border: 1px solid $borderColor;
		border-radius: 20px;
		.radio-items-content {
			padding: 30px;
			padding-bottom: 20px;
		}

		.item {
			display: grid;
			grid-template-columns: 72px 1fr;
			align-content: space-around;
			justify-content: space-between;	
			width: 100%;
			margin-bottom: 20px;
			cursor: pointer;
			label {
				cursor: pointer;
				display: flex;
				align-items: center;
			}
			.radio {
				border: 1px solid #e3e3e3;
				width: 52px;
				height: 52px; 
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				.inner {
					width: 48px;
					height: 48px;
					background-color: white;
					opacity: 1.0;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;	
				}
				span {
					vertical-align: center;
					align-items: center;
					justify-content: center;
				}
			}
			.radio-selected {
				border: 1px solid $tintColor !important;
				.inner {
					span {
						color: white;
					}
				}
			}
		}	
		.item:hover {
			.radio.selectable {
				border: 1px solid #e3e3e3;
				.inner {
					background-color: #e3e3e3;	
					opacity: 1.0;
				}
			}	
				
		}	
	}
}
.component-item-rate {
	.radio-items {
		margin-bottom: 20px;
		width: "100%";
		display: grid;
		grid-template-columns: repeat(11, auto);
		align-content: space-around;
		justify-content: space-between;
	}

	.radio-items .radio {
		mmargin: 0 auto 0 auto;
	}

	.radio-labels {
		width: "100%";
		display: grid;
		color: #9C9C9C;
		font-size: 18px;
		grid-template-columns: repeat(3, auto);
		align-content: space-around;
		justify-content: space-between;
		@media only screen and (max-width: $breakpoint-small) {   
			font-size: 14px;
			text-align: center;
		}
	}

	.radio-labels span:first-child {
		@media only screen and (max-width: $breakpoint-small) {   		
			text-align: left;
		}
	}
	.radio-labels span:last-child {
		@media only screen and (max-width: $breakpoint-small) {   		
			text-align: right;
		}
	}

}

.component-quiz, .component-checkbox {

	.quiz-items,  .checkbox-item {
		clip-path: margin-box;
		padding-bottom: 0px;
		padding-top: 0px;
		border: 1px solid $borderColor;
		border-radius: 20px;
	}

	.quiz-item {
		width: 100%;
		display: flex;
		align-items: center;
		
		
		.radio {
			margin-left: 20px;
			float: left;
		}

		label {

			width: calc(100% - 60px);
			line-height: 28px;
			padding-left: 25px;
			display: flex;
			align-items: center;;
			padding-top: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid $borderColor;
			@media only screen and (max-width: $breakpoint-small) {   		
				min-height: auto;
				padding-top: 24px;
				padding-bottom: 24px;
				padding-right: 15px;
				line-height: 22px;
				vertical-align: center;
			}	
		}
	}


	.checkbox-item {
		.quiz-item {
			border-radius: 20px;
		}
	}

	.checkbox-item {
		cursor: pointer;
		.quiz-item {
			min-height: 100px;
			label {
				border-bottom: 0px !important;
			}
		}
	}	

	.quiz-items.answers, .checkbox-item {
		label {
			cursor: pointer;
		}
		
		.quiz-item:hover {
			background-color: #F9F9F9;
		}
	}
	// .answer:hover .radio {
		
	// 	.inner {
	// 		opacity: 1.0;
	// 	}
	// }

	.quiz-items .quiz-item:last-child  {
		label {
			border: 0px;
		}
	}

}

.step section.component-text {
	margin-bottomm: 35px;
}
.component .component-text {
	font-size: 18px;
	line-height: 1.5;

	h3 {
		border: 0px;
	}

	h2 {
		font-size: 32px;
	}

	.style-callout {
		border-radius: 20px;
		background-color: #F5F8FF;
		padding: 50px;
	}
}

.radio {
	cursor: pointer;
	width: 24px;
	height: 24px;
	@media only screen and (max-width: $breakpoint-small) {   
		width: 19px;
		height: 19px;	
	}
	border-radius: 50%;
	border: 1.0px solid silver;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	.inner {
		
		lleft: 2px;
		ttop: 2px;
		pposition: relative;
		width: 20px;
		height: 20px;

		@media only screen and (max-width: $breakpoint-small) {   
			left: 0px;
			top: 0px;	
			width: 19px;
			height: 19px;	
		}
	
		border-radius: 50%;
		background-color:  $tintColor;	
		opacity: 0.0;
	}
}

.quiz-item:hover .radio.selectable, .radio.selectable:hover {
	border: 1.0px solid  $tintColor;
	.inner {
		background-color: #e3e3e3;	
		opacity: 1.0;
	}
}

.radio-selected {
	.inner {
		background-color: $tintColor !important;	
		opacity: 1.0;
	}
}

.radio-correct {
	border: 1.0px solid green;
	.inner {
		background-color: green;	
		opacity: 1.0;
		background-image: url(/white-checkmark.png);
		background-size: 10px 10px;
		background-repeat: no-repeat;
		background-position: center center;;
	}
}

.radio-incorrect {
	border: 1.5px solid red;
	background: url('/white-incorrect.png');
	.inner {
		background-color: red;	
		opacity: 1.0;
		background-image: url(/white-cross.png);
		background-size: 10px 10px;
		background-repeat: no-repeat;
		background-position: center center;;
	}
}

.component-skills {
	.intro {
		font-weight: 700;
		border: 0px;
		border-bottom: 1px solid #e3e3e3;
		margin-bottom: 0px;
	}
}

/**

Skill Card

**/
.skill-card {
	background-color: #F8F8F8;
	padding: 0px;
	border: 5px solid #F8F8F8;
	border-radius: 6pt;
	color: $tintColor;
	font-size: 21px;
	font-weight: 600;
	width: "100%";
	cursor: pointer;
	@include unselectable;	
	min-height: 160px;
	transition: 0.2s;
	h4 {
		
		padding-left: 110px;
		border: 0px !important;
		margin-top: -70px !important;

		@media only screen and (max-width: $breakpoint-small) {   
			padding-left: 0px;
			margin-top: 5px !important;
		}
		color: $tintColor;
		color: $black;
		font-size: 22px;
	}

	p {
		padding-left: 110px;
		@media only screen and (max-width: $breakpoint-small) {   
			padding-left: 0px;
		}
		margin-top: 8px;
		font-weight: 400;
		font-size: 18px;
		color: rgba(0,0,0,0.6);
		ffont-family: "SpaceGrotesk"
	}

	.icon {
		margin-bottom: 6px;
	}

	.description {
		
	}
}

.skill-card.condensed:hover, .skill-card.selected {
	background-color: #E2ECFF;
	bbackground-color: white;
	bborder: 5px solid white;
	bbox-shadow: 0px 10px 30px rgba(0,0,0,0.15);
	border: 5px solid #E2ECFF;
}

.skill-card {
	min-height: 50px;
	margin-bottom: 20px;
	padding: 0px;
	background-color: white;
	border: 0px;
	padding-bottom: 35px;
	padding-top: 50px;
	margin-bottom: 0px;
	border-bottom: 1px solid #e3e3e3;
}

.skill-card.condensed {
	min-height: 50px;
	margin-bottom: 20px;
	padding: 30px;

	background-color: #F8F8F8;
	padding: 20px;
	border: 5px solid #F8F8F8;
	border-radius: 6pt;

	min-height: 45px;
	margin-bottom: 0px;
	padding: 30px;
	padding-bottom: 20px;
	p, h4 {
		margin: 0px !important;
		padding: 0px !important;
	}
	h4 {
		padding: 0px !important;
		
	}
	h4 {
		ccolor: $tintColor;
	}
	.icon {
		margin-bottom: 2px;
	}


	p {
		margin-top: 5px !important;
		font-weight: 500;
		font-size: 15px;
		color: rgba(0,0,0,0.4);
	}
	p {
		opacity: 0.0;
	}
	.inner {
		transition: transform 0.3s;
	}
	p {
		transition: opacity 0.2s;
	}
}
.skill-card.condensed {
	.inner {
		transform: translateY(17px);
	}
}
.skill-card.condensed:hover, .skill-card.selected {
	.inner {
		display: block;
		transform: translateY(-6px);
	}
	p {
		opacity: 1.0;
	}
}
.skill-card.condensed.large {
	.icon img {
		transform: scale(1.6);
		margin-left: 10px;
		margin-top: 6px;
		margin-bottom: 10px;
	}
}

.skill-card.condensed.centered {
	padding-top: 30px;
	padding-bottom: 10px;

	.icon {
		ttransform: scale(1.5);
		padding: 6px;
		img {
			margin-left: 0px;
			margin-bottom: px;
		}
	}
}

.skill-card.condensed.selected {

	h4 {
		color: $tintColor;
	}

	border: 5px solid $tintColor;
}

.skill-card.centered {
	text-align: center;
}

.skill-card.selected {

	h4 {
		color: $tintColor;
	}

	border: 5px solid $tintColor;
}



.step section.component-continue {
	margin-bottom: 10px;
}

section.section-continue {
	margin-bottom: 25px;
	div h3 {
		border: 0px;
		padding-bottom: 0px;
	}
	.content {
		@media only screen and (max-width: $breakpoint-small) {
			padding: 0px;
		}
	}
}


/**

Skill Card

**/
.pickbox-card {
	background-color: #F8F8F8;
	padding: 0px;
	border: 5px solid #F8F8F8;
	border-radius: 6pt;
	color: $tintColor;
	font-size: 21px;
	font-weight: 600;
	width: "100%";
	cursor: pointer;
	@include unselectable;	
	min-height: 160px;
	transition: 0.2s;
	h4 {
		
		padding-left: 110px;
		border: 0px !important;
		margin-top: -70px !important;

		@media only screen and (max-width: $breakpoint-small) {   
			padding-left: 0px;
			margin-top: 5px !important;
		}
		color: $tintColor;
		color: $black;
		font-size: 18px;
		line-height: 1.4;
		font-weight: 600;
		text-align: left;
		width: 100%;
	}

	p {
		padding-left: 110px;
		@media only screen and (max-width: $breakpoint-small) {   
			padding-left: 0px;
		}
		margin-top: 8px;
		font-weight: 400;
		font-size: 18px;
		color: rgba(0,0,0,0.6);
		ffont-family: "SpaceGrotesk"
	}

	.icon {
		margin-bottom: 6px;
	}

	.description {
		
	}
}

.pickbox-card.selectable:hover  {
	background-color: #E2ECFF;
	border: 5px solid #E2ECFF;
}

.pickbox-card {
	min-height: 50px;
	margin-bottom: 20px;
	padding: 0px;
	background-color: white;
	border: 0px;
	padding-bottom: 35px;
	padding-top: 50px;
	margin-bottom: 0px;
	border-bottom: 1px solid #e3e3e3;
}

.pickbox-card.condensed {
	min-height: 50px;
	margin-bottom: 20px;
	padding: 30px;

	background-color: #F8F8F8;
	padding: 30px;
	border: 5px solid #F8F8F8;
	border-radius: 6pt;

	min-height: 45px;
	margin-bottom: 0px;
	display: flex;
	align-items: center;
	

	p, h4 {
		margin: 0px !important;
		padding: 0px !important;
	}
	h4 {
		padding: 0px !important;
		
	}
	h4 {
		ccolor: $tintColor;
	}
	.icon {
		margin-bottom: 2px;
	}


	p {
		margin-top: 5px !important;
		font-weight: 500;
		font-size: 15px;
		color: rgba(0,0,0,0.4);
	}
	p {
		opacity: 0.0;
	}
	.inner {
		transition: transform 0.3s;
	}
	p {
		transition: opacity 0.2s;
	}
}

.pickbox-card.condensed:hover, .pickbox-card.selected {
	.inner {
		display: block;
	}
	p {
		opacity: 1.0;
	}
}
.pickbox-card.condensed.large {
	.icon img {
		transform: scale(1.6);
		margin-left: 10px;
		margin-top: 6px;
		margin-bottom: 10px;
	}
}


.pickbox-card.condensed.selected {

	h4 {
		color: $tintColor;
	}

	border: 5px solid $tintColor;
}

.pickbox-card.centered {
	h4 {
		text-align: center;
	}
}

.pickbox-card.selected {

	h4 {
		color: $tintColor;
	}

	border: 5px solid $tintColor;
}



.email-form {
	box-shadow: 0px 20px 50px rgba(0,0,0,0.1);
	min-height: 100px;
	width: 100%;
	border-radius: 20px;
}

.email-content {
	padding: 40px;
	p { 
		padding: 10px;
	}
	input {
		border: 0px;
		border-bottom: 2px solid $tintColor;
		font-size: 18px;
		outline: none;
		color: $tintColor;
	}
}
.email-footer {
	border-top: 1px solid #e3e3e3;
	display: flex;
	justify-content: flex-start;
	align-items: right;
	padding: 40px;
	padding-top: 40px;
	.download-button {
		cursor: pointer;
		padding: 20px;
		font-weight: bold;
		border-radius: 10px;
		background-color: #eeeeee;
		background-color:  #F5F8FF;
	}
	.download-button:hover {
		background-color: $tintColor;
		color: white;
	}
}